import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable } from 'rxjs';
import { CustomerPreference } from '../interface/customer-preference';
import { ReleaseConfig } from '../interface/release-config';
import { AuthService } from '../shared/auth.service';
import { CreateJobRequest } from '../interface/create-job-request'
import { NglComboboxOptionItem } from 'ng-lightning';
import { JobDetail } from '../interface/job-detail';
import { PackageInstallRecord } from '../interface/package-install-record';
import { TextMessage } from '../shared/text-message';
import { CommonService } from '../shared/common.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Tag } from '../interface/tag';
import { CreateUpgradeScheduleRequest } from '../interface/create-upgrade-schedule-request';
import { Organization } from '../interface/organization';
import { AccessControlEnum, PackageAccessControl } from '../interface/package-access-control';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Version } from '@angular/compiler';
import { AssociateOrgIdWithSmartTagIds } from '../interface/push-upgrade-smart-tag';
import { HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-admin-registered-org-list',
  templateUrl: './admin-registered-org-list.component.html',
  styleUrls: ['./admin-registered-org-list.component.css']
})
export class AdminRegisteredOrgListComponent implements OnInit {

  displayedColumns: string[] = ['select', 'orgId', 'name', 'orgRegistrationDate', 'emailId', 'smartTags', 'releaseDisplayName', 'status', 'action'];
  packageDisplayedColumns: string[] = ['index', 'packageName', 'productName', 'previousPackageVersion', 'packageVersion', 'taskDuration', 'sfdcRequestStartTime', 'sfdcRequestEndTime', 'packageStatus', 'comments'];
  sanityDisplayedColumns: string[] = ['subTaskType', 'packageStatus', 'comments', 'taskDuration', 'resultUrl'];
  scheduleOrgsDisplayedColumns: string[] = ["orgId", "name"];

  public smartTagList: NglComboboxOptionItem[];
  smartTagSelection = [];
  openSmartTag = false;
  disableSmartTagLnkBtn: boolean = true;

  org_details: Organization;
  dataSource = new MatTableDataSource<CustomerPreference>();
  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  searchText: string = '';
  isSandboxTab: boolean = true;

  isLoading: boolean = false;
  showTopToast = false;
  toastMsg: string;
  toastVariant: string;
  disableBulkUpgradeBtn: boolean = true;
  currentActiveReleaseInfo: ReleaseConfig = {
    id: null, displayLabel: null, name: null, lmaName: null,
    isCurrentRelease: true, gaDate: null, minDate: null, maxDate: null, patchNo: null,
    releaseVersion: new Version("0.0.0"), isCummulative: false, isActive: true, canBeDeactivated: false
  };
  CreateJobRequest: CreateJobRequest = { registeredOrgs: null, releaseInfo: null };
  openReleaseMenu: boolean = false;
  releaseList: ReleaseConfig[];
  public releaseDropdownList: NglComboboxOptionItem[];
  selection: string;
  open = false;
  openUpgradeModal = false;
  openOrgDetailsModal = false;
  isEarlyFeedbackEnabledForAdmin: boolean = false;
  releaseDropdownInputCtrl: FormControl = new FormControl();
  filteredReleaseDropdown$: Observable<any[]>;

  jobDetails: JobDetail;
  packageDataSource = new MatTableDataSource<PackageInstallRecord>();
  sanityDataSource = new MatTableDataSource<PackageInstallRecord>();
  scheduleOrgsDataSource = new MatTableDataSource<CustomerPreference>();
  checkboxselection = new SelectionModel<CustomerPreference>(true, []);
  timeZone: string;
  tzTimeZone: string;
  selectedTab: any = 'sandbox';
  showActiveOrg: boolean = true;

  showScheduleUpgradeModal: boolean = false;
  tags: Tag[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tagsVisible = true;
  tagsSelectable = true;
  tagsRemovable = true;
  tagsAddOnBlur = true;

  instPackageDisplayedColumns: string[] = ['index', 'packageName', 'productName', 'packageVersion', 'accessibility'];
  packageAccessCtrlDataSource = new MatTableDataSource<PackageAccessControl>();
  packageAccessControl: PackageAccessControl[];
  installForCustomProfileFeatureEnabled: boolean = true;

  cleaveOptions = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'MMM', 'Y']
  };
  scheduleName: string;
  scheduleDate: Date;
  minScheduleDate: Date;//  = this.scheduleDate;
  scheduleTimeSelection: string = null;
  scheduleDateHourSelection: string = null;
  scheduleDateMinutesSelection: string = null;
  scheduleTimeOptions: NglComboboxOptionItem[];
  scheduleDateHours: string[];// ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
  scheduleDateMinutes: string[];//["00","15","30","45"];
  scheduleTimeOpen: true;
  scheduleHoursOpen: true;
  scheduleMinsOpen: true;
  createUpgradeScheduleRequest: CreateUpgradeScheduleRequest = { id: null, releaseConfigId: null, tags: [], orgIds: [], scheduleDate: null, scheduleName: null, productLine: [], description: null, isSandbox: null };

  adobeSignErrorMsg: string = "";
  opened = false;
  promptHeader = 'Adobe Sign Validation Error';
  prompt = 'error';

  confirmOptOut: boolean = false;

  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public authservice: AuthService, public commonService: CommonService) {

  }

  ngOnInit(): void {
    var date = new Date();
    this.timeZone = /\((.*)\)/.exec(date.toString())[1];
    this.tzTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.scheduleDate = new Date();
    this.minScheduleDate = new Date();

    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {
      //this.getAllRegisteredOrgList();
      this.getRegisteredOrgList();
    }
    else {
      this.authservice.redirectToAdminLogin();
    }
  }

  ngAfterViewInit() {
    this.paginator._intl.firstPageLabel = "First Page";
    this.paginator._intl.lastPageLabel = "Last Page";
    this.paginator._intl.previousPageLabel = "Prev Page";
    this.paginator._intl.nextPageLabel = "Next Page";
    this.paginator._intl.itemsPerPageLabel = "Display Records per page";

    this.dataSource.paginator = this.paginator;
  }

  openToast(msg: string, success: Boolean = false) {
    this.toastMsg = msg;
    this.isLoading = false;
    if (success)
      this.toastVariant = 'success';
    else
      this.toastVariant = 'error';

    this.showTopToast = true;
  }

  onClose(reason: string) {
    console.log(`Closed by ${reason}`);
  }

  /** Method triggered to fetch/refresh the Push-Upgrade Jobs records */
  refresh(): void {
    this.getRegisteredOrgList();
  }

  getAllRegisteredOrgList(): void {
    this.checkboxselection.clear()
    this.isLoading = true;
    let registeredOrgs = this.authservice.getAllRegisteredOrgList();
    let currentActiveRelease = this.authservice.getCurrentActiveReleaseInfo()
    forkJoin([registeredOrgs, currentActiveRelease]).subscribe(response => {
      var data = response[0];
      this.currentActiveReleaseInfo = response[1];
      data.forEach(function (item) {
        // item.salesForceEdition = item.type;
        // item.isSandBox ? item.type = "Sandbox" : item.type = "Production";
        item.releaseDisplayName ? null : item.releaseDisplayName = '-';
      });
      this.dataSource = new MatTableDataSource<CustomerPreference>(data);
      this.dataSource.sort = this.tableOneSort;
      // PU-748 Handle sorting with case insensitive behaviour.
      this.dataSource.sortingDataAccessor = (data, header) => this.authservice.isEmpty(data[header]) ? "" : data[header].toString().toLowerCase();

      this.isLoading = false;
    },
      error => {
        console.log(error)
      });
  }

  getRegisteredOrgList(): void {
    this.checkboxselection.clear();
    this.isLoading = true;
    this.displayedColumns = ['select', 'orgId', 'name', 'orgRegistrationDate', 'emailId', 'smartTags', 'releaseDisplayName', 'status', 'action'];
    let registeredOrgs = this.authservice.getRegisteredOrgList(this.searchText, this.pageIndex + 1, this.pageSize, this.tzTimeZone, this.isSandboxTab, this.showActiveOrg);
    let currentActiveRelease = this.authservice.getCurrentActiveReleaseInfo();
    let isEarlyFeedbackFeatureEnabledForAdmin = this.authservice.getIsEarlyFeedbackFeatureEnabledForAdmin();
    let SmartTagsList = this.authservice.getAllSmartTagsList();


    forkJoin([registeredOrgs, currentActiveRelease, isEarlyFeedbackFeatureEnabledForAdmin, SmartTagsList]).subscribe(response => {
      var data = response[0].data;
      this.length = response[0].totalCount;
      this.pageIndex = response[0].pageNo - 1;
      this.pageSize = response[0].pageSize;

      this.currentActiveReleaseInfo = response[1];
      this.isEarlyFeedbackEnabledForAdmin = response[2];
      this.smartTagList = response[3].map((item, i) => {
        return {
          value: item.id,
          label: item.name
        };
      });

      data.forEach(function (item) {
        // item.salesForceEdition = item.type;
        // item.isSandBox ? item.type = "Sandbox" : item.type = "Production";
        item.releaseDisplayName ? null : item.releaseDisplayName = '-';
      });
      this.dataSource = new MatTableDataSource<CustomerPreference>(data);
      this.dataSource.sort = this.tableOneSort;
      // PU-748 Handle sorting with case insensitive behaviour.
      this.dataSource.sortingDataAccessor = (data, header) => this.authservice.isEmpty(data[header]) ? "" : data[header].toString().toLowerCase();

      this.isLoading = false;
    },
      error => {
        console.log(error)
      });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getRegisteredOrgList();
  }

  //**Apply filter to the Org list displayed on the UI */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.searchText != filterValue.trim().toLowerCase()) {
      this.searchText = filterValue.trim().toLowerCase();
      // this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.searchText.length == 0 || this.searchText.length >= 3) {
        // PU-1168 Need to reset the page index as 0 at the time of search.
        this.pageIndex = 0;
        this.getRegisteredOrgList();
      }
    }
  }

  /** Whether the number of selected elements matches the total number of Orgs  */
  isAllSelected() {
    const numSelected = this.checkboxselection.selected.length;
    var enabledCheckboxCount = 0;
    this.dataSource.filteredData.forEach(row => this.checkToDisableUpgrade(row) ? null : enabledCheckboxCount++);
    return numSelected === enabledCheckboxCount;
  }

  /** Selects all rows if they are not all selected and enable the Bulk Upgrade button;
   *  otherwise clear selection and disable the Bulk Upgrade button. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.checkboxselection.clear();
      this.disableBulkUpgradeBtn = true;
    }
    else {
      this.dataSource.filteredData.forEach(row => this.checkToDisableUpgrade(row) ? null : this.checkboxselection.select(row));
      this.disableBulkUpgradeBtn = false;
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CustomerPreference): string {
    if (!row) {
      this.checkboxselection.hasValue() ? this.disableBulkUpgradeBtn = false : this.disableBulkUpgradeBtn = true;
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
  }

  triggerBulkPushUpgrade() {
    this.isLoading = true;
    this.CreateJobRequest.releaseInfo = this.currentActiveReleaseInfo;
    this.CreateJobRequest.registeredOrgs = this.checkboxselection.selected;
    var releaseName = this.currentActiveReleaseInfo.name;
    this.CreateAndInvokeUpgradeJob(releaseName);
  }

  private CreateAndInvokeUpgradeJob(releaseName: string) {
    this.authservice.CreateAndTriggerUpgradeJob(this.CreateJobRequest)
      .subscribe(
        (response: object) => {
          var success_msg = 'Initiated Push Upgrade for ' + this.checkboxselection.selected.length + ' Org(s)';
          this.openToast(success_msg, true);
          this.isLoading = false;
          this.checkboxselection.selected.forEach(x => x.releaseTriggeredDate = new Date().toISOString());
          this.checkboxselection.clear();
          this.cancelReleaseSelectionMenu();
        },
        (err) => {
          this.adobeSignErrorMsg = "";
          var msg = err.split("\n");
          this.isLoading = false;
          if (msg[0] != "Error Code: 400") {
            this.openToast(msg[1], false);
          }
          else {
            this.adobeSignErrorMsg = "";
            for (var ctr = 1; ctr < msg.length; ctr++) {
              if (ctr === 1) {
                var replacedMsg = msg[ctr].replace("Message: ", "");
                this.adobeSignErrorMsg += replacedMsg;
              }
              else {
                this.adobeSignErrorMsg += msg[ctr];
              }
              this.adobeSignErrorMsg += "<br><br>";
            }
            this.cancelReleaseSelectionMenu()
            this.openErrorPrompt();
          }
        });
  }

  openReleaseSelectionMenu(element: CustomerPreference) {
    this.adobeSignErrorMsg = "";
    this.isLoading = true;
    this.selection = null;
    this.checkboxselection.clear();
    this.checkboxselection.selected.push(element);
    this.authservice.getAllReleaseInfo().subscribe((response: ReleaseConfig[]) => {
      this.releaseList = response;

      var existingrelease = response.filter(x => x.name === element.currentReleaseVersion);

      if (this.authservice.isEmpty(element.releasePatchNo) == false && this.authservice.isEmpty(existingrelease) == false
        && existingrelease[0].patchNo != element.releasePatchNo) {
        var patchRelease = response.filter(x => x.lmaName == existingrelease[0].lmaName && x.patchNo == element.releasePatchNo);
        if (this.authservice.isEmpty(patchRelease) == false) {
          existingrelease = patchRelease;
        }
      }

      var releaseList = response.map((item, i) => {
        var isDisable = false;

        if (this.authservice.isEmpty(existingrelease)) {
          isDisable = false;
        }
        else if (this.authservice.isEmpty(item.gaDate)) {
          isDisable = true;
        }
        else {
          var currentReleaseVersion = new Version(existingrelease[0].releaseVersion.major + "." + element.releasePatchNo);
          var itemReleaseVersionString = item.releaseVersion.major + "." + this.commonService.getVersionString(item.releaseVersion.minor);

          if (item.releaseVersion["patch"] != null) {
            itemReleaseVersionString = itemReleaseVersionString + "." + this.commonService.getVersionString(item.releaseVersion["patch"]);

          } else if (item.releaseVersion["build"] != null) {
            itemReleaseVersionString = itemReleaseVersionString + "." + this.commonService.getVersionString(item.releaseVersion["build"]);
          }

          var itemReleaseVersion = new Version(itemReleaseVersionString);
          var versionComparision = this.commonService.versionCompare(currentReleaseVersion, itemReleaseVersion);
          if (versionComparision == 1) {
            // existing relese is ahead of current item
            isDisable = true;
          } else if (versionComparision == -1) {
            // existing relese is before of current item
            isDisable = false;
          } else {
            // existing relese is equal to current item
            if (item.isCummulative) {
              isDisable = element.currentReleaseVersion.indexOf("AllPatches") > 0
            } else {
              isDisable = true;
            }
          }
        }

        return {
          value: item.id,
          label: item.isCummulative ? item.name : item.displayLabel,
          disabled: isDisable
        };
      });

      this.releaseDropdownList = releaseList;
      this.releaseDropdownInputCtrl.reset();
      this.filteredReleaseDropdown$ = this.releaseDropdownInputCtrl.valueChanges
        .pipe(
          startWith(''),
          map(val => !val ? [...this.releaseDropdownList] : this.filterReleaseDropdownList(val))
        );

      this.openReleaseMenu = !this.openReleaseMenu;
      this.isLoading = false;
    });
  }

  cancelReleaseSelectionMenu() {
    this.openReleaseMenu = false;
  }

  filterReleaseDropdownList(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.releaseDropdownList.filter(release => release.label.toLowerCase().indexOf(filterValue) > -1);
  }

  openReleaseMenuDialog(rowdata: any) {
    this.isLoading = true;
    this.adobeSignErrorMsg = "";
    // this.orgRowData = rowdata;
    this.openReleaseMenu = true;
  }

  submitJob() {
    this.adobeSignErrorMsg = "";
    if (this.authservice.isEmpty(this.selection)) {
      var msg = TextMessage.selectReleaseName;
      this.openToast(msg, false);
      return;
    }
    this.isLoading = true;
    this.CreateJobRequest.releaseInfo = this.releaseList.find(x => x.id === this.selection);
    this.CreateJobRequest.registeredOrgs = this.checkboxselection.selected;
    var releaseName = this.CreateJobRequest.releaseInfo.name;
    this.CreateAndInvokeUpgradeJob(releaseName);
  }

  openUpgradeDetailsDialog(registeredOrg: CustomerPreference) {
    this.isLoading = true;
    this.getUpgradeDetailsDialog(registeredOrg);
  }

  openOrgShowMoreDetailsDialog(registeredOrg: CustomerPreference) {
    this.isLoading = true;
    this.disableSmartTagLnkBtn = true;
    this.getOrgShowMoreDetailsDialog(registeredOrg);
  }

  getOrgShowMoreDetailsDialog(registeredOrg: CustomerPreference): void {
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {

      this.authservice.generateSFOrgAccessTokenByRefreshTokenAndSave(registeredOrg.orgId).subscribe((result: any) => {
        if (result) {
          let installedPackageInfo = this.authservice.getAccessControlInfo(registeredOrg.orgId, true);
          let customerPreference = this.authservice.getSalesForceOrgCustomerPreference(registeredOrg.orgId);
          forkJoin([customerPreference, installedPackageInfo]).subscribe(response => {
            this.org_details = response[0];
            this.org_details.isEarlyFeedbackEnabled = registeredOrg.isEnrolledForEarlyFeedback;
            response[0].isSandbox ? (this.org_details.isSandbox = "Sandbox") : this.org_details.isSandbox = "Production";
            this.isLoading = false;
            this.openOrgDetailsModal = true;

            this.packageAccessControl = response[1];
            this.smartTagSelection = this.org_details.smartTagIds;

            this.packageAccessCtrlDataSource = response[1];
            let packageAccessControlhasdata = this.packageAccessControl.length > 0;
            this.installForCustomProfileFeatureEnabled = packageAccessControlhasdata ? this.packageAccessControl[0].isCustomProfileEnabled : this.installForCustomProfileFeatureEnabled;

            if (!this.installForCustomProfileFeatureEnabled) {
              var index = this.packageDisplayedColumns.indexOf("installForSpecificProfiles");    // <-- Not supported in <IE9
              if (index !== -1) {
                this.packageDisplayedColumns.splice(index, 1);
              }
              // If the InstallForCustomProfiles feature is disabled then set those value to default i.e InstallForAdminOnly.
              this.packageAccessControl.forEach(x => {
                if (x.accessibility.toUpperCase() === AccessControlEnum.InstallForCustomProfiles.toString().toUpperCase()) {
                  x.accessibility = AccessControlEnum.InstallForAdminOnly.toString();
                }
              })
            }
            var display = false;

            this.packageAccessControl.forEach(x => {
              if (!x.isInstalledPackage) {
                display = true;
                return;
              }
            });
            this.isLoading = false;
          },
            error => {
              this.openToast("Error Occured while loading package info", false);
            });
        }
        else {
          this.openToast("Error Occured while generating Access Token", false);
        }

      },
        error => {
          this.openToast("Error Occured while generating Access Token", false);
        });
    }
  }

  getPackageAccessbility(accessibility: string) {
    switch (accessibility.toUpperCase()) {
      case AccessControlEnum.InstallForAdminOnly.toString().toUpperCase():
        return "Install For Admin Only"
      case AccessControlEnum.InstallForAllUsers.toString().toUpperCase():
        return "Install For All Users"
      case AccessControlEnum.InstallForCustomProfiles.toString().toUpperCase():
        return "Install For Specific Profiles"
      default:
        return "Install For Admin Only"
    }
  }

  triggerWelcomeEmail() {
    this.authservice.triggerOrgRegistrationEmail(this.org_details.orgId).subscribe(data => {
      if (data)
        this.openToast("Organization registration email sent sucessfully.", true);
    }, error => {
      this.openToast("Error in sending Organization registration email.", false);
    });
  }

  getUpgradeDetailsDialog(registeredOrg: any): void {
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {
      this.authservice.getUpgradeDetailsDialog(registeredOrg.orgId, registeredOrg.customerPreferenceId, registeredOrg.currentReleaseVersion).subscribe((data: any) => {
        this.jobDetails = data;
        var packagesArray = data.packageInstallRecords.filter((ele) => ele.taskType == null || ele.taskType == "PackageUpgrade");
        for (let index = 0; index < packagesArray.length; index++) {
          var pkg = packagesArray[index];
          pkg.index = index + 1;
        }
        this.packageDataSource = packagesArray;
        this.sanityDataSource = data.packageInstallRecords.filter((ele) => ele.taskType == "Sanity" || ele.taskType == "UpgradeValidation");
        for (let i = 0; i < this.jobDetails.packageInstallRecords.length; i++) {
          this.jobDetails.packageInstallRecords[i].taskDurationInString = this.commonService.getTimeDuration(new Date(this.jobDetails.packageInstallRecords[i].taskStartTime), this.jobDetails.packageInstallRecords[i].taskEndTime);
        }
        this.isLoading = false;
        this.openUpgradeModal = true;
      },
        error => {
          console.log(error)
        });
    }
  }

  disableMasterCheckbox() {
    let disableMasterCheckbox: Boolean = true;
    this.dataSource.filteredData.forEach(x => this.checkToDisableUpgrade(x) ? null : disableMasterCheckbox = false)
    return disableMasterCheckbox
  }

  getActionMenuTooltip(element: CustomerPreference): string {

    if (element.currentReleaseVersion === this.currentActiveReleaseInfo.name) {
      if (element.releasePatchNo) {
        if (element.releasePatchNo === this.currentActiveReleaseInfo.patchNo) {
          return "Organization is on latest release.";
        }
      } else {
        return "Organization is on latest release.";
      }
    }

    if (!this.authservice.isEmpty(element.releaseTriggeredDate)) {
      return "Upgrade job triggered on " + this.authservice.dateFormatter(element.releaseTriggeredDate) + " is in Progress";
    }

    if (!this.authservice.isEmpty(element.scheduleId)) {
      return "Push Upgrade is scheduled for the Org.";
    }

    return "Initiate Push Upgrade.";
  }

  getOrgStatusToolTip(element: CustomerPreference) {
    if (element.status === 'Inactive')
      return "Not able to generate access token using refresh token."

    if (element.status === 'Disabled')
      return "LMA has no record of active Conga package(s)."

    if (element.status === 'Stale')
      return "Org was refreshed."

    if (element.status === 'OptedOut')
      return "Org is Opted out."

    return "Org is active."
  }

  checkToDisableUpgrade(element: CustomerPreference) {
    if (!this.authservice.isEmpty(element.releaseTriggeredDate) || !this.authservice.isEmpty(element.scheduleId) || this.checkOrgStatusToDisableUpgrade(element.status)) {
      return true;
    } else if (element.currentReleaseVersion === this.currentActiveReleaseInfo.name) {
      if (element.releasePatchNo) {
        if (element.releasePatchNo === this.currentActiveReleaseInfo.patchNo) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  }

  checkOrgStatusToDisableUpgrade(orgStatus: String) {
    if (orgStatus === 'Inactive' || orgStatus === 'Disabled' || orgStatus === 'Stale' || orgStatus === 'OptedOut')
      return true;

    return false;
  }

  navigateToEarlyFeedbackDashboard(orgId: string) {
    this.isLoading = true;
    this.authservice.getEarlyFeedbackURL(orgId).subscribe((earlyFeedbackURL: any) => {
      this.isLoading = false;
      window.open(earlyFeedbackURL);
    });
  }

  //**Format date to display on UI */
  dateFormatter(inputDate: string) {
    var date = this.authservice.dateFormatter(inputDate);
    if (this.authservice.isEmpty(this.timeZone))
      this.timeZone = /\((.*)\)/.exec(date.toString())[1];
    date = date.split('(')[0];
    return date;
  }

  tabChange(tabName: string) {
    this.pageIndex = 0;
    this.showActiveOrg = true;
    switch (tabName) {
      case "production": {
        this.isSandboxTab = false;
        break;
      }
      case "sandbox": {
        this.isSandboxTab = true;
        break;
      }
      default: {
        this.isSandboxTab = true;
        break;
      }
    }
    this.getRegisteredOrgList();
  }

  //#region Schedule Upgrade Methods
  openScheduleUpgradeModal() {
    this.isLoading = true;
    this.tags = [];
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {

      // check if any Org is already scheduled
      // TODO: Check based on scheduleId/scheduleName/scheduleDate, if any selected Org is alreay a part of existing schedule
      // If so, display a warning message to show those Orgs and confirm from user if they want to proced or not
      // If yes, when creating schedule, those Orgs has to be removed from existing schedule before putting into new schedule
      // If no, close popup

      // // Get Active release Date and compare with today and acoording to set min and max date
      this.getScheduleDates();

      // Prepare scheduleTimeOptions:
      // this.prepareScheduleTimeOptions(this.scheduleDate);

      // this.scheduleDateHours = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
      // this.scheduleDateMinutes = ["00","15","30","45"];
      // this.scheduleDateHourSelection = "00";
      // this.scheduleDateMinutesSelection = "00";

      // this.setupScheduleName(this.scheduleDate);

      // prepare Orgs list for selected Orgs
      this.scheduleOrgsDataSource.data = this.checkboxselection.selected;

      this.showScheduleUpgradeModal = true;
    }
    else {
      this.authservice.redirectToAdminLogin();
    }
    this.isLoading = false;
  }

  prepareScheduleTimeOptions(inputDate: Date) {

    this.scheduleTimeOptions = [];
    let hrs = 0;
    var todayDate = new Date();
    if (todayDate.getDate() == inputDate.getDate() &&
      todayDate.getMonth() == inputDate.getMonth()) {
      hrs = todayDate.getHours() + 2;
    }

    for (; hrs < 24; hrs++) {
      var paddedHrs = (hrs < 10 ? "0" : "") + hrs.toString();

      for (let mins = 0; mins < 46; mins = mins + 15) {
        var paddedMins = (mins < 10 ? "0" : "") + mins.toString();

        let item: NglComboboxOptionItem = {
          value: paddedHrs + ":" + paddedMins,
          label: paddedHrs + ":" + paddedMins
        };

        this.scheduleTimeOptions.push(item);
      }
    }

    this.scheduleTimeSelection = this.prepareScheduleTimeOptions.length > 0 ? this.scheduleTimeOptions[0].value.toString() : null;
    this.scheduleTimeChange(this.scheduleTimeOptions[0].value.toString());
  }

  getScheduleDates() {
    this.authservice.GetScheduleDates(this.currentActiveReleaseInfo.name).subscribe((data: ReleaseConfig) => {
      // Get Active release Date and compare with today and acoording to set min and max date
      var todayDate = new Date();
      var parsedMinDate = new Date(Date.parse(data.minDate.toString()));

      if (todayDate < parsedMinDate) {
        this.scheduleDate = this.getDate(parsedMinDate);
        this.minScheduleDate = this.getDate(parsedMinDate);
      } else {
        if (todayDate.getHours() > 22) {
          todayDate.setDate(todayDate.getDate() + 1);
        }
        this.scheduleDate = this.getDate(todayDate);
        this.minScheduleDate = this.getDate(todayDate);
      }
      this.prepareScheduleTimeOptions(this.scheduleDate);
      this.setupScheduleName(this.scheduleDate);
    }, error => {
      console.log(error);
    });
  }

  getDate(fromDate: Date, addDays: number = 0): Date {
    var date = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
    if (addDays > 0) {
      date.setDate(date.getDate() + addDays);
    }

    return date;
  }

  scheduleDateChange(selectedDate: Date) {
    this.prepareScheduleTimeOptions(selectedDate);
    this.setupScheduleName(selectedDate);
    this.prepareScheduleTimeOptions(selectedDate);
  }

  scheduleTimeChange(selectedItem: string) {
    var hrs = selectedItem.substring(0, selectedItem.indexOf(":"));
    var mins = selectedItem.replace(hrs + ":", "");
    this.scheduleDateHourSelection = hrs;
    this.scheduleDateMinutesSelection = mins;

    this.setupScheduleName(this.scheduleDate);
  }

  setupScheduleName(inputDate: Date) {
    this.scheduleName = this.commonService.getCurrentReleaseLabel(this.currentActiveReleaseInfo) + ":"
      + inputDate.getDate() + "-" + inputDate.toDateString().substr(4, 3) + "-" + inputDate.getFullYear()
      + " " + this.scheduleDateHourSelection + ":" + this.scheduleDateMinutesSelection;
    var scheduleHours: number = +this.scheduleDateHourSelection;
    var scheduleMins: number = +this.scheduleDateMinutesSelection;
    this.scheduleDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), scheduleHours, scheduleMins, 0)
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.tags.push({ name: value });
    }

    // Clear the input value
    event.input.value = "";
  }

  removeTag(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  saveUpgradeSchedule() {
    this.isLoading = true;
    // Validations
    // Call to API to save scheduleUpgrade

    this.createUpgradeScheduleRequest.scheduleName = this.scheduleName;
    this.createUpgradeScheduleRequest.releaseConfigId = this.currentActiveReleaseInfo.id;
    this.createUpgradeScheduleRequest.isSandbox = this.isSandboxTab;

    this.createUpgradeScheduleRequest.orgIds = [];
    this.scheduleOrgsDataSource.data.forEach(orgData => {
      this.createUpgradeScheduleRequest.orgIds.push(orgData.orgId);
    });

    this.createUpgradeScheduleRequest.tags = [];
    this.tags.forEach(tag => {
      this.createUpgradeScheduleRequest.tags.push(tag.name);
    });

    this.createUpgradeScheduleRequest.scheduleDate = this.scheduleDate;

    this.authservice.CreateUpgradeSchedule(this.createUpgradeScheduleRequest).subscribe((data: any) => {
      var success_msg = 'Push Upgrade schedule created for ' + this.checkboxselection.selected.length + ' Org(s)';
      this.openToast(success_msg, true);
      this.isLoading = false;
      //this.checkboxselection.selected.forEach(x => x.releaseTriggeredDate = new Date().toISOString());
      this.checkboxselection.clear();
      //this.cancelReleaseSelectionMenu();
      this.closeScheduleUpgradeModal();
      this.refresh();
    },
      (err) => {
        var msg = err.split("\n");
        this.isLoading = false;
        if (msg[0] != "Error Code: 400") {
          this.openToast(msg[1], false);
        } else {
          this.adobeSignErrorMsg = "";
          for (var ctr = 1; ctr < msg.length; ctr++) {
            if (ctr === 1) {
              var replacedMsg = msg[ctr].replace("Message: ", "");
              this.adobeSignErrorMsg += replacedMsg;
            }
            else {
              this.adobeSignErrorMsg += msg[ctr];
            }
            this.adobeSignErrorMsg += "<br><br>";
          }
          this.closeScheduleUpgradeModal()
          this.openErrorPrompt();
        }
      }
    );
  }

  closeScheduleUpgradeModal() {
    this.showScheduleUpgradeModal = false;
  }

  closeOrgInfoModal() {
    this.openOrgDetailsModal = false;
  }

  convertBoolToString(value: boolean) {
    return value ? "Yes" : "No";
  }

  setAdobeConsentStatus() {
    if (this.org_details != null) {
      this.isLoading = true;
      this.authservice.SetAdobeConsentStatus(this.org_details.orgId, !this.org_details.enableExternalDependentPackageInstallation).subscribe(response => {
        if (response.success) {
          this.openToast("Enabled Adobe Package Installation successfully", true);
          this.org_details.enableExternalDependentPackageInstallation = !this.org_details.enableExternalDependentPackageInstallation;
        } else {
          this.openToast(response.message, false);
        }
      });
    }
  }
  //#endregion

  toggleShowActiveOrgFilter(event: any) {
    this.showActiveOrg = event.target.checked;
    this.pageIndex = 0;
    this.getRegisteredOrgList();
  }

  openErrorPrompt() {
    this.opened = !this.opened;
  }

  cancelErrorPrompt() {
    this.adobeSignErrorMsg = "";
    this.opened = false;
  }

  getSmartTagNameById(smartTagId: string) {
    return this.smartTagList[this.smartTagList.map(x => x.value).indexOf(smartTagId)].label;
  }

  remove(smartTagId: string) {
    this.smartTagSelection = this.smartTagSelection.filter((s) => s !== smartTagId);
    this.disableSmartTagLnkBtn = false;
  }

  onSmartTagSelection() {
    this.disableSmartTagLnkBtn = false;
  }

  UpdateSmartTagLink(orgID: string) {
    this.isLoading = true;
    let associateOrgIdWithSmartTagIds: AssociateOrgIdWithSmartTagIds = { smartTagIds: this.smartTagSelection, orgId: orgID };
    this.authservice.AssociateOrgIdWithSmartTagIds(associateOrgIdWithSmartTagIds).subscribe((data) => {
      let success_msg: string = "Smart tag linked with selected Orgs.";
      this.openToast(success_msg, true);
      this.isLoading = false;
      this.disableSmartTagLnkBtn = true;
    },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.openToast("System Error Occured!", false);
      });
  }

  exportOrgListReport() {
    this.isLoading = true;

    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.authservice.getAllRegisteredOrgListReport(zone).subscribe((res: HttpResponse<Blob>) => {
      this.commonService.downloadFileFromAPIRes(res);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      console.error(err);
    });
  }

  onConfirmOptOut() {
    this.isLoading = true;
    this.authservice.optOutOfCongaUpgrade(this.org_details.organizationName, this.org_details.orgId).subscribe(res => {
      this.closeOrgInfoModal();
      this.isLoading = this.confirmOptOut = false;
      if (res) {
        this.refresh();
      }
    }, () => {
      this.refresh();
      this.closeOrgInfoModal();
      this.isLoading = this.confirmOptOut = false;
    })
  }
}
