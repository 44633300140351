<app-admin-navigation-panel></app-admin-navigation-panel>

<!-- Admin Dashboard to view Customer preference settings and trigger Push Upgrade -->

<div class="main"><br><br>
    <button class="slds-button slds-icon_container slds-icon-utility-info btn-download-org-list"
        [nglTooltip]="'Export the Subscriber Org Details to Excel'" nglTooltipInteractive="true" nglTooltipDelay="100"
        nglTooltipOpenAuto (click)="exportOrgListReport()">
        <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#download">
            </use>
        </svg>
    </button>
    <ngl-tabset [(selected)]='selectedTab' variant='scoped'>
        <ng-template ngl-tab label="Sandbox" (activate)="tabChange('sandbox')">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__figure">
                                <span class="slds-icon_container slds-icon-standard-opportunity">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity">
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Push Upgrade Registered Sandbox Org Report">Registered Sandbox
                                                Org Information</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" nglButton variant="brand"
                                    (click)="openScheduleUpgradeModal()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Schedule Upgrade</span>
                                </button>
                                <button type="button" *readOnlyBtn nglButton variant="brand"
                                    (click)="triggerBulkPushUpgrade()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Upgrade to
                                        {{this.commonService.getCurrentReleaseLabel(currentActiveReleaseInfo)}}</span>
                                </button>
                                <button type="button" nglButton variant="brand" (click)="refresh()"
                                    aria-live="assertive">
                                    <span class="slds-text-not-selected">Refresh</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #heading>Production</ng-template>
        <ng-template id="production" ngl-tab #summaryTab="nglTab" [label]="heading"
            (activate)="tabChange('production')">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__figure">
                                <span class="slds-icon_container slds-icon-standard-opportunity">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity">
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Push Upgrade Registered Sandbox Org Report">Registered Production
                                                Org Information</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button type="button" *readOnlyBtn nglButton variant="brand"
                                    (click)="openScheduleUpgradeModal()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Schedule Upgrade</span>
                                </button>
                                <button type="button" *readOnlyBtn nglButton variant="brand"
                                    (click)="triggerBulkPushUpgrade()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Upgrade to
                                        {{this.commonService.getCurrentReleaseLabel(currentActiveReleaseInfo)}}</span>
                                </button>
                                <button type="button" nglButton variant="brand" (click)="refresh()"
                                    aria-live="assertive">
                                    <span class="slds-text-not-selected">Refresh</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngl-tabset>

    <br>

    <div class="slds-page-header">
        <div class="data-body">

            <div class="slds-page-header">
                <mat-form-field>
                    <div class="status-toggle-filter">
                        <ngl-checkbox-toggle label="Display Only Active Orgs">
                            <input ngl type="checkbox" [checked]="showActiveOrg"
                                (click)="toggleShowActiveOrgFilter($event)">
                        </ngl-checkbox-toggle>
                    </div>
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Search Text" #input>
                </mat-form-field>
                <div class="data-container mat-elevation-z8">
                    <table #TableOneSort="matSort" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox *readOnlyBtn (change)="$event ? masterToggle() : null"
                                    [checked]="checkboxselection.hasValue() && isAllSelected()" color="primary"
                                    [disabled]="disableMasterCheckbox()"
                                    [indeterminate]="checkboxselection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef=" let element">
                                <mat-checkbox *readOnlyBtn (click)="$event.stopPropagation()"
                                    (change)="$event ? checkboxselection.toggle(element) : null"
                                    [checked]="checkboxselection.isSelected(element)"
                                    [disabled]="this.checkToDisableUpgrade(element)"
                                    [aria-label]="checkboxLabel(element)" color="primary">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Job ID Column -->
                        <ng-container matColumnDef="jobId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.jobId}} </td>
                        </ng-container>

                        <!-- Org ID Column -->
                        <ng-container matColumnDef="orgId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Id </th>
                            <td mat-cell *matCellDef="let element">
                                {{this.commonService.removeOrgIdCheksum(element.orgId)}} </td>
                        </ng-container>

                        <!-- Org Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>

                        <!-- Organizaation Registration Date Column -->
                        <ng-container matColumnDef="orgRegistrationDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Registration Date <br> ({{timeZone}})
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.orgRegistrationDateStr}} </td>
                        </ng-container>

                        <!-- Organizaation Registered User Email ID Column -->
                        <ng-container matColumnDef="emailId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </th>
                            <td mat-cell *matCellDef="let element"> {{element.emailId}} </td>
                        </ng-container>

                        <!-- Organization Smart Tags Column -->
                        <ng-container matColumnDef="smartTags">
                            <th mat-header-cell *matHeaderCellDef> Smart Tags </th>
                            <td mat-cell *matCellDef="let element">
                                <ngl-pill *ngFor="let tag of  element.smartTags | slice: 0 : 3">
                                    {{tag}}
                                </ngl-pill>
                                <span class="more-tag-link" *ngIf="element.smartTags.length > 3"
                                    [nglTooltip]="element.smartTags.join(', ')" nglTooltipInteractive="true"
                                    nglTooltipDelay="100" nglTooltipOpenAuto>
                                    more</span>
                            </td>
                        </ng-container>

                        <!-- Current Release Column -->
                        <ng-container matColumnDef="releaseDisplayName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Release </th>
                            <td mat-cell *matCellDef="let element"> {{element.releaseDisplayName}} </td>
                        </ng-container>

                        <!-- Org Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let element">
                                <div [nglTooltip]="getOrgStatusToolTip(element)" nglTooltipInteractive="true"
                                    nglTooltipDelay="100" nglTooltipOpenAuto> {{element.status}} </div>
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element">

                                <div class="flex columns align-flex-start">
                                    <div class="flex rows">
                                        <div class="flex columns">
                                            <span [nglTooltip]="getActionMenuTooltip(element)"
                                                nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto>
                                                <button *readOnlyBtn
                                                    class="slds-button slds-icon_container slds-icon-utility-info"
                                                    [disabled]="checkToDisableUpgrade(element)"
                                                    (click)="openReleaseSelectionMenu(element)">
                                                    <svg class="slds-button__icon" aria-hidden="true">
                                                        <use
                                                            xlink:href="/assets/icons/action-sprite/svg/symbols.svg#upload">
                                                        </use>
                                                    </svg>
                                                </button>
                                            </span>

                                            <button class="slds-button slds-icon_container slds-icon-utility-info"
                                                [disabled]="this.authservice.isEmpty(element.currentReleaseVersion) ||  element.status === 'OptedOut'"
                                                [nglTooltip]="'Release Details'" nglTooltipInteractive="true"
                                                nglTooltipDelay="100" nglTooltipOpenAuto
                                                (click)="openUpgradeDetailsDialog(element)">
                                                <svg class="slds-button__icon" aria-hidden="true">
                                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#task">
                                                    </use>
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="flex columns">
                                            <ng-container *ngIf="this.isEarlyFeedbackEnabledForAdmin">
                                                <button *readOnlyBtn [disabled]="element.status === 'OptedOut'"
                                                    class="slds-button slds-icon_container slds-icon-utility-info"
                                                    [nglTooltip]="'Early Feedback'" nglTooltipInteractive="true"
                                                    nglTooltipDelay="100" nglTooltipOpenAuto
                                                    (click)="navigateToEarlyFeedbackDashboard(element.orgId)">
                                                    <svg class="slds-button__icon" aria-hidden="true">
                                                        <use
                                                            xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#feedback">
                                                        </use>
                                                    </svg>
                                                </button>
                                            </ng-container>
                                            <button *readOnlyBtn
                                                class="slds-button slds-icon_container slds-icon-utility-info"
                                                [nglTooltip]="'Show More Details'" nglTooltipInteractive="true"
                                                nglTooltipDelay="100" nglTooltipOpenAuto
                                                (click)="openOrgShowMoreDetailsDialog(element)">
                                                <svg class="slds-button__icon" aria-hidden="true">
                                                    <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#more">
                                                    </use>
                                                </svg>
                                            </button>
                                        </div>
                                        <span *ngIf="!this.authservice.isEmpty(element.failedJobReleaseName)"
                                            class="slds-icon_container slds-icon-utility-info">
                                            <div [nglTooltip]="interactive" nglTooltipInteractive="true"
                                                nglTooltipDelay="100" nglTooltipOpenAuto>
                                                <svg class="slds-icon slds-icon-text-error slds-icon_small"
                                                    aria-hidden="true">
                                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#info">
                                                    </use>
                                                </svg>
                                                <ng-template #interactive>Last Upgrade job for
                                                    {{element.failedJobReleaseName}} run triggered on
                                                    {{this.authservice.dateFormatter(element.failedJobReleaseTriggeredDate)}}
                                                    failed <br> For more details refer "Show More Details" for
                                                    respective job in Push Upgrade Job Details tab </ng-template>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr class="org-element-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9" style="text-align: center;">No results found</td>
                        </tr>
                    </table>
                </div>
                <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
                </mat-paginator>
            </div>
        </div>
    </div>

    <!-- Modal Popup for the Conga Release Selection Menu -->
    <ngl-modal header="Conga Release Selection Menu" [(open)]="openReleaseMenu">
        <div class="slds-p-around_medium">
            <ngl-combobox label="Release" [options]="filteredReleaseDropdown$ | async" [(open)]="open"
                [(selection)]="selection" variant="lookup">
                <input nglCombobox placeholder="Select an Option" [formControl]="releaseDropdownInputCtrl">
            </ngl-combobox>
            <br>
            <p>Note : Upgrades only allowed from older release to latest release</p>
        </div>
        <ng-template nglModalFooter>
            <button class="slds-button slds-button_neutral" (click)="cancelReleaseSelectionMenu()">Cancel</button>
            <button class="slds-button slds-button_brand" (click)="submitJob()">Submit</button>
        </ng-template>
    </ngl-modal>

    <ngl-modal header="Push Upgrade Details" [(open)]="openUpgradeModal" size="large" *ngIf="openUpgradeModal">
        <div class="slds-p-around_medium">
            <div>
                <table class="table borderless">
                    <tbody>
                        <tr>
                            <td>Organization ID</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.commonService.removeOrgIdCheksum(jobDetails.orgId)"></td>
                            <td>Organization Name</td>
                            <td><input ngl type="text" disabled [(ngModel)]="jobDetails.orgName"></td>
                        </tr>
                        <tr>
                            <td>Job ID</td>
                            <td><input ngl type="text" disabled [(ngModel)]="jobDetails.jobId"></td>
                            <td>Push Upgrade Status</td>
                            <td><input ngl type="text" disabled [(ngModel)]="jobDetails.jobStatus"></td>
                        </tr>
                        <tr>
                            <td>Consent Provided By</td>
                            <td><input ngl type="text" disabled [ngModel]="jobDetails.consentProviderUserName"></td>
                            <td>Consent Creation Date</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.authservice.dateFormatter(jobDetails.consentProvidedDate)"></td>
                        </tr>
                        <tr>
                            <td>Push Upgrade Start Date</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.authservice.dateFormatter(jobDetails.startDate)"></td>
                            <td>Push Upgrade End Date</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.authservice.dateFormatter(jobDetails.endDate)"></td>
                        </tr>
                        <tr>
                            <td>Job Created By</td>
                            <td><input ngl type="text" disabled [(ngModel)]="jobDetails.createdBy"></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <br>

                <h3 *ngIf="packageDataSource.length > 0">Package Details</h3>
                <div *ngIf="packageDataSource.length > 0" class="detail-data-container mat-elevation-z8">
                    <table mat-table [dataSource]="packageDataSource" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

                        <!-- Index Column -->
                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef> Order </th>
                            <td mat-cell *matCellDef="let element"> {{element.index}} </td>
                        </ng-container>

                        <!-- Pacakge Name Column -->
                        <ng-container matColumnDef="packageName">
                            <th mat-header-cell *matHeaderCellDef> Package Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.packageName}} </td>
                        </ng-container>

                        <!-- Product Name Column -->
                        <ng-container matColumnDef="productName">
                            <th mat-header-cell *matHeaderCellDef> Product Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
                        </ng-container>

                        <!-- Existing Package Version Column -->
                        <ng-container matColumnDef="previousPackageVersion">
                            <th mat-header-cell *matHeaderCellDef> Pre-Upgrade<br>Package Version </th>
                            <td mat-cell *matCellDef="let element"> {{element.previousPackageVersion}} </td>
                        </ng-container>

                        <!-- Package Version Column -->
                        <ng-container matColumnDef="packageVersion">
                            <th mat-header-cell *matHeaderCellDef> Post-Upgrade<br>Package Version </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container *ngIf="this.authservice.isEmpty(element.documentationUrl); else withLink">
                                    {{element.packageVersion}}
                                </ng-container>
                                <ng-template #withLink>
                                    <a href="{{this.commonService.getURLFromTag(element.documentationUrl)}}"
                                        target="_blank"><span
                                            title="{{this.commonService.getURLFromTag(element.documentationUrl)}}">{{element.packageVersion}}</span></a>
                                </ng-template>
                            </td>
                        </ng-container>

                        <!-- Package Install Task Duration Column -->
                        <ng-container matColumnDef="taskDuration">
                            <th mat-header-cell *matHeaderCellDef> Upgrade Duration </th>
                            <td mat-cell *matCellDef="let element"> {{element.taskDurationInString}} </td>
                        </ng-container>

                        <!-- Package Status Column -->
                        <ng-container matColumnDef="packageStatus">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.packageStatusDisplayText}} </td>
                        </ng-container>

                        <ng-container matColumnDef="sfdcRequestStartTime">
                            <th mat-header-cell *matHeaderCellDef> SFDC Request Sent<br>({{
                                this.authservice.getTimeZone() }})</th>
                            <td mat-cell *matCellDef="let element"> {{
                                this.authservice.dateFormatter(element.sfdcRequestStartTime, false) }} </td>
                        </ng-container>

                        <ng-container matColumnDef="sfdcRequestEndTime">
                            <th mat-header-cell *matHeaderCellDef> SFDC Response Receieved<br>({{
                                this.authservice.getTimeZone() }})</th>
                            <td mat-cell *matCellDef="let element"> {{
                                this.authservice.dateFormatter(element.sfdcRequestEndTime, false) }} </td>
                        </ng-container>

                        <!-- Comments Column -->
                        <ng-container matColumnDef="comments">
                            <th mat-header-cell *matHeaderCellDef> Comments </th>
                            <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="packageDisplayedColumns; sticky: true"></tr>
                        <tr class="package-element-row" mat-row *matRowDef="let row; columns: packageDisplayedColumns;">
                        </tr>
                    </table>
                </div>
                <br>

                <h3 *ngIf="sanityDataSource.length > 0">Conga Automation Framework Validation Results</h3>
                <div *ngIf="sanityDataSource.length > 0" class="detail-data-container mat-elevation-z8">
                    <table mat-table [dataSource]="sanityDataSource" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

                        <!-- SubTaskType Column -->
                        <ng-container matColumnDef="subTaskType">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.subTaskType}} </td>
                        </ng-container>

                        <!-- Package Status Column -->
                        <ng-container matColumnDef="packageStatus">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.packageStatus}} </td>
                        </ng-container>

                        <!-- Comments Column -->
                        <ng-container matColumnDef="comments">
                            <th mat-header-cell *matHeaderCellDef> Notes </th>
                            <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
                        </ng-container>

                        <!-- UpgradeValidation Task Duration Column -->
                        <ng-container matColumnDef="taskDuration">
                            <th mat-header-cell *matHeaderCellDef> Duration </th>
                            <td mat-cell *matCellDef="let element"> {{element.taskDurationInString}} </td>
                        </ng-container>

                        <!-- ResultUrl Column -->
                        <ng-container matColumnDef="resultUrl">
                            <th mat-header-cell *matHeaderCellDef> Results </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-template [ngIf]="element.resultUrl"><a href="{{element.resultUrl}}"
                                        target="_blank">click here</a> for results</ng-template>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="sanityDisplayedColumns; sticky: true"></tr>
                        <tr class="package-element-row" mat-row *matRowDef="let row; columns: sanityDisplayedColumns;">
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <ng-template nglModalFooter>
        </ng-template>
    </ngl-modal>

    <!-- Modal Popup for the Organization Show More Deatils -->
    <ngl-modal header="Organization Details" [(open)]="openOrgDetailsModal" size="large" *ngIf="openOrgDetailsModal">
        <div class="slds-p-around_medium">
            <div>
                <table class="table borderless org-tbl">
                    <tbody>
                        <tr>
                            <td>Organization ID</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.commonService.removeOrgIdCheksum(org_details.orgId)"></td>
                            <td>Organization Name</td>
                            <td><input ngl type="text" disabled [(ngModel)]="org_details.organizationName"></td>
                        </tr>
                        <tr>
                            <td>Organization Type</td>
                            <td><input ngl type="text" disabled [(ngModel)]="org_details.isSandbox"></td>
                            <td>Organization Edition</td>
                            <td><input ngl type="text" disabled [(ngModel)]="org_details.organizationType"></td>
                        </tr>
                        <tr>
                            <td>Registered By</td>
                            <td><input ngl type="text" disabled [ngModel]="org_details.createdBy"></td>
                            <td>Registration Date</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.authservice.dateFormatter(org_details.createdDate)"></td>
                        </tr>
                        <tr>
                            <td>Early Feedback Enabled</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="convertBoolToString(org_details.isEarlyFeedbackEnabled)"></td>
                            <td>Registered User Email</td>
                            <td><input ngl type="text" disabled [ngModel]="org_details.emailId"></td>
                        </tr>
                        <tr>
                            <td>Linked Smart Tag</td>
                            <td>
                                <ngl-combobox multiple [options]="this.smartTagList" [(open)]="openSmartTag"
                                    [(selection)]="smartTagSelection" (selectionChange)="onSmartTagSelection()">
                                    <input readOnlyField nglCombobox placeholder="Select smart tags to link">
                                </ngl-combobox>
                                <div class="slds-m-top_xx-small" *ngIf="this.smartTagList">
                                    <ng-container *ngFor="let smartTagId of smartTagSelection">
                                        <ngl-pill class="smartTag-pill" (remove)="remove(smartTagId)">{{
                                            this.getSmartTagNameById(smartTagId) }}</ngl-pill>
                                    </ng-container>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <button *readOnlyBtn class="slds-button slds-button_brand"
                                        [disabled]="disableSmartTagLnkBtn"
                                        (click)="UpdateSmartTagLink(org_details.orgId)"> Update Link </button>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngIf="org_details.isAdobePkgInstalled">
                            <td>Enable Adobe Package Installation</td>
                            <td>
                                <input readOnlyField ngl type="text" disabled
                                    [ngModel]="convertBoolToString(org_details.enableExternalDependentPackageInstallation)">
                            </td>
                            <td>
                                <div>
                                    <button *readOnlyBtn class="slds-button slds-button_brand"
                                        [disabled]="org_details.enableExternalDependentPackageInstallation"
                                        (click)="setAdobeConsentStatus()"> Enable </button>
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>
                                <input ngl type="text" disabled [ngModel]="org_details.orgStatus">
                            </td>
                            <td>
                                <button *readOnlyBtn class="slds-button slds-button_brand"
                                    [disabled]="org_details.orgStatus === 'OptedOut'" (click)="confirmOptOut = true">
                                    Opt
                                    Out </button>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Enable Autoupgrade for GA</td>
                            <td>
                                <input readOnlyField ngl type="text" disabled
                                    [ngModel]="convertBoolToString(org_details.enableAutoUpgradeForGA)">
                            </td>
                            <td>                                
                            </td>
                            <td>
                            </td>
                        </tr>
                        <!-- PU-2012 Hiding the below row. The Requiremnt is not clear. -->
                        <tr *ngIf="false">
                            <td>AccountId</td>
                            <td>
                                <input readOnlyField ngl type="text" disabled
                                    [ngModel]="org_details.accountId">
                            </td>
                            <td>                                
                            </td>
                            <td>
                            </td>
                        </tr>
                        <!-- PU-2012 Hiding the below row. The Requiremnt is not clear. -->
                        <tr *ngIf="false">
                            <td>Opt-out From Next GA Release</td>
                            <td>
                                <input readOnlyField ngl type="text" disabled
                                    [ngModel]="convertBoolToString(org_details.optOutForNextGARelease)">
                            </td>
                            <td>                                
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>

                <h3 *ngIf="packageAccessCtrlDataSource.length > 0">Package Details</h3>
                <div class="detail-data-container mat-elevation-z8">
                    <table mat-table [dataSource]="packageAccessCtrlDataSource" class="pkgAccessCtrlModal">

                        <!-- Index Column -->
                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef> Index </th>
                            <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                        </ng-container>

                        <!-- Pacakge Name Column -->
                        <ng-container matColumnDef="packageName">
                            <th mat-header-cell *matHeaderCellDef> Package Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.packageName}}</td>
                        </ng-container>

                        <!-- Product Name Column -->
                        <ng-container matColumnDef="productName">
                            <th mat-header-cell *matHeaderCellDef> Product Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.productName}}</td>
                        </ng-container>

                        <!-- Package Version Column -->
                        <ng-container matColumnDef="packageVersion">
                            <th mat-header-cell *matHeaderCellDef> Package Version </th>
                            <td mat-cell *matCellDef="let element"> {{element.packageVersion}}</td>
                        </ng-container>

                        <!-- Pacakge Accessibility Column -->
                        <ng-container matColumnDef="accessibility">
                            <th mat-header-cell *matHeaderCellDef> Accessibility </th>
                            <td mat-cell *matCellDef="let element"> {{getPackageAccessbility(element.accessibility)}}
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="instPackageDisplayedColumns; sticky: true"></tr>
                        <tr class="package-element-row" mat-row
                            *matRowDef="let row; columns: instPackageDisplayedColumns;"></tr>
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="5" style="text-align: center;">No Packages found</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <ng-template nglModalFooter>
            <button class="slds-button slds-button_neutral" (click)="closeOrgInfoModal()">Cancel</button>
            <button *readOnlyBtn [disabled]="org_details.orgStatus==='OptedOut'" class="slds-button slds-button_brand"
                (click)="triggerWelcomeEmail()">Trigger Welcome
                Email</button>
        </ng-template>
    </ngl-modal>

    <!-- <app-push-upgrade-schedule-modal></app-push-upgrade-schedule-modal> -->
    <ngl-modal header="Schedule Upgrade(s)" [(open)]="showScheduleUpgradeModal" size="large">
        <div class="slds-p-around_medium">
            <div>
                <table class="table borderless">
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td colspan="3">
                                {{ scheduleName }}</td>
                        </tr>
                        <tr>
                            <td>Target Release</td>
                            <td>{{this.commonService.getCurrentReleaseLabel(currentActiveReleaseInfo)}}</td>
                            <td>Proposed Schedule</td>
                            <td>
                                <ngl-pill class="schedule-date-text">
                                    {{this.authservice.dateFormatter(scheduleDate.toString())}}</ngl-pill>
                            </td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td>
                                <ngl-datepicker-input [(ngModel)]="scheduleDate" #dt="ngModel"
                                    [class.slds-has-error]="!dt.valid" [showToday]="true" [min]="minScheduleDate"
                                    [cleave]="cleaveOptions" (valueChange)="scheduleDateChange($event)">
                                    <input nglDatepickerInput type="text" readonly>
                                </ngl-datepicker-input>
                            </td>
                            <td>Time</td>
                            <td>
                                <ngl-combobox [(open)]="scheduleTimeOpen" [options]="scheduleTimeOptions"
                                    [(selection)]="scheduleTimeSelection"
                                    (selectionChange)="scheduleTimeChange($event)">
                                    <input nglCombobox>
                                </ngl-combobox>
                            </td>
                        </tr>
                        <tr>
                            <td>Tags</td>
                            <td colspan="3">
                                <form-field class="tag-chip-list">
                                    <mat-chip-list #chipList aria-label="Tags">
                                        <mat-chip *ngFor="let tag of tags" [selectable]="tagsSelectable"
                                            [removable]="tagsRemovable" (removed)="removeTag(tag)">
                                            {{tag.name}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="New Tag..." [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="tagsAddOnBlur"
                                            (matChipInputTokenEnd)="addTag($event)">
                                    </mat-chip-list>
                                </form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <h3>Org Details</h3>
                <div class="detail-data-container mat-elevation-z8">
                    <table mat-table [dataSource]="scheduleOrgsDataSource" class="mat-elevation-z8">
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

                        <!-- Organization Id Column -->
                        <ng-container matColumnDef="orgId">
                            <th mat-header-cell *matHeaderCellDef> Organization Id </th>
                            <td mat-cell *matCellDef="let element">
                                {{this.commonService.removeOrgIdCheksum(element.orgId)}} </td>
                        </ng-container>

                        <!-- Organization Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Organization Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="scheduleOrgsDisplayedColumns; sticky: true"></tr>
                        <tr class="package-element-row" mat-row
                            *matRowDef="let row; columns: scheduleOrgsDisplayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
        <ng-template nglModalFooter>
            <button class="slds-button slds-button_neutral" (click)="closeScheduleUpgradeModal()">Cancel</button>
            <button class="slds-button slds-button_brand" (click)="saveUpgradeSchedule()">Submit</button>
        </ng-template>
    </ngl-modal>

    <!-- Loading Icon while making backend call -->
    <div class="loading_spinner" *ngIf="isLoading">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
</div>

<div class="slds-notify_container">
    <ngl-toast iconName="utility:info" class="toast-info" *ngIf="showTopToast" [(variant)]="toastVariant"
        (close)="onClose($event); showTopToast = false;" duration="3000">
        <h3 class="slds-text-heading_small">{{toastMsg}}</h3>
    </ngl-toast>
</div>


<ngl-modal [header]="promptHeader" [(open)]="opened" [prompt]="prompt" dismissOnClickOutside="false">
    <div class="slds-p-around_medium">
        <div [innerHtml]="this.adobeSignErrorMsg"></div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="cancelErrorPrompt()">Okay</button>
    </ng-template>
</ngl-modal>

<ngl-modal header="Warning" [(open)]="confirmOptOut" [prompt]="'warning'" dismissOnClickOutside="false">
    <div class="slds-p-around_medium">
        <p>
            Are you sure to opt out the {{org_details?.organizationName}} : {{org_details?.orgId}} from the Conga
            Upgrade
            Programme?
        </p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="onConfirmOptOut()">Opt Out</button>
        <button class="slds-button slds-button_neutral" (click)="confirmOptOut = false">Cancel</button>
    </ng-template>
</ngl-modal>